<template>  
    <legal-company ref="editLegalCompany" legalCompanyMode="Provider"/>
</template>

<script>   

const LegalCompany = () => import('@/../../shared/components/LegalCompany/LegalCompany')

export default {
    name: 'ProviderSettingsFinancialTab', 
    components: {
        LegalCompany
	}
}
</script>